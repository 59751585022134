#main {
  margin-top: 60px;
  float: left;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-50 {
  width: 50%;
}
