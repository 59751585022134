header {
  width: 100%;
  height: 60px;
  background-color: #156;
  color: #fff;
  margin: 0px;
  padding: 0px;
}

header a {
  padding: 0px 10px 0px 10px;
  color: rgb(212, 212, 212);
  width: 50px;
  text-align: center;
  text-decoration: none;
  line-height: 60px;
}
header a:hover {
  background-color: rgb(30, 143, 172);
}

.nav {
  float: right;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  left: 50px;
  margin-left: 60px;
  float: left;
}

@media only screen and (max-width: 600px) {
  header {
    height: 120px;
  }
  .logo {
    margin-left: 45%;
  }
  .nav {
    width: 100%;
    margin-right: 0px;
  }
}
